import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [adjective, setAdjective] = useState('Nombre')
  const [noun, setNoun] = useState('para Instagram')

  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]

  const generateUsername = () => {
    const randomAdj = selectRandomItem(data.wordsJson.spanish.adjectives)
    const randomNoun = selectRandomItem(data.wordsJson.spanish.nouns)
    setAdjective(randomAdj.toLowerCase())
    setNoun(randomNoun.charAt(0).toUpperCase() + randomNoun.slice(1))
  }

  useEffect(() => {
    generateUsername()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de nombres para Instagram originales"
        titleSeo="Generador de nombres para Instagram originales"
        description="Herramienta para generar nombres para Instagram aleatorios y originales, con usuarios disponibles."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {noun}
            </Text>{' '}
            {adjective}
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de nombres para Instagram originales
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => generateUsername()}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de nombres para Instagram</Heading1>
        <Paragraph>
          Son cada d&iacute;a m&aacute;s las personas que buscan un generador de
          nombres para Instagram originales que est&eacute;n disponibles y
          listos para ser usados, dado que es a d&iacute;a de hoy una de las
          redes sociales m&aacute;s populares, comprada por Facebook y habiendo
          a estas alturas superado a la red social original en t&eacute;rminos
          de tendencias y modas.
        </Paragraph>
        <Paragraph>
          Al contar con tantos usuarios ya registrados, sean personas
          f&iacute;sicas, empresas, marcas o simplemente cuentas de
          entretenimiento o informaci&oacute;n, resulta dif&iacute;cil hallar
          nombres de usuarios para Instagram que est&eacute;n disponibles y sean{' '}
          <em>chulos</em>, sin necesidad de recurrir a repetir caracteres de
          forma innecesaria, agregar molestos n&uacute;meros o utilizar
          demasiados caracteres.
        </Paragraph>
        <Paragraph>
          Pero no es solo all&iacute; donde la utilidad de un generador de
          nombres de usuario para Instagram entra en juego, porque m&aacute;s
          all&aacute; de encontrar nombres que est&eacute; listos para ser
          registrados, tambi&eacute;n resulta muchas veces necesario el dar con
          nombres creativos y originales que sean atractivos.
        </Paragraph>
        <Paragraph>
          M&aacute;s all&aacute; del nombre de usuario en s&iacute; mismo, que
          deber&aacute; ser &uacute;nico y ser&aacute; el utilizado para hacer
          menciones a la cuenta y en la URL del perfil, existe tambi&eacute;n el
          nombre a mostrar, que posee menos restricciones y da m&aacute;s lugar
          a la creatividad.
        </Paragraph>
        <Heading2>
          C&oacute;mo dar con nombres para Instagram que sean originales e
          interesantes
        </Heading2>
        <Paragraph>
          Puede parecer una tarea trivial, pero ante tantas opciones atractivas
          disponibles y tantas otras ya existentes que es mejor no repetir, el
          generar un nombre de usuario para Instagram puede complicarse y tomar
          m&aacute;s tiempo y esfuerzo de lo esperado inicialmente.
        </Paragraph>
        <Paragraph>
          De escoger un nombre gen&eacute;rico y no muy espec&iacute;fico, nos
          encontraremos ante una gran dificultad al intentar construir una marca
          alrededor de nuestro perfil, al momento de promocionarnos y que la
          gente nos reconozca, cuando la gente intente mencionarnos y compartir
          nuestra cuenta mediante el boca a boca, y, en general, no tendremos
          una buena experiencia destacando nuestra presencia en la red social
          por sobre los perfiles similares que no solo publican contenido que se
          asemeja al nuestro, sino que adem&aacute;s tienen nombres
          pr&aacute;cticamente id&eacute;nticos.
        </Paragraph>
        <Paragraph>
          Por otra parte, en el caso de que sean originales y demos con un nuevo
          nombre que es &uacute;nico peor muy complejo y poco atractivo,
          estaremos da&ntilde;ando el potencial de nuestra cuenta en
          t&eacute;rminos de popularidad y difusi&oacute;n porque si bien tiene
          un nombre propio que no se repite, es tedioso el recordarlo al momento
          de hablar de &eacute;l o hacer una menci&oacute;n.
        </Paragraph>
        <Paragraph>
          Finalmente, est&aacute; el componente subjetivo respecto a qu&eacute;
          nombre nos puede parecer atractivo que a su vez est&eacute; disponible
          y sea f&aacute;cil de compartir. Este no es un aspecto a menospreciar
          dado que tener que pensar desde cero en un nombre para Instagram que
          sea chulo y original, ya sea en espa&ntilde;ol o en ingl&eacute;s,
          puede llevarnos a una espiral de opciones y variaciones sin
          permitirnos tomar una decisi&oacute;n final y escoger el nombre
          definitivo.
        </Paragraph>
        <Heading3>
          Un generador de nombres para Instagram puede acelerar el proceso
        </Heading3>
        <Paragraph>
          Una herramienta para generar nombres para Instagram puede encargarse
          autom&aacute;ticamente de verificar la disponibilidad de los nombres
          sugeridos, as&iacute; como contar con una lista de t&eacute;rminos
          populares y no tan populares, para evitar nombres muy utilizados y dar
          con los m&aacute;s atractivos y ex&oacute;ticos.
        </Paragraph>
        <Paragraph>
          Porque m&aacute;s all&aacute; de que su nombre nos pueda llevar a
          pensar que solo cumple la funci&oacute;n de generar una lista de
          nombres originales para Instagram, una herramienta como la menciona es
          capaz de asegurarnos que est&aacute;n disponibles para su registro y
          evitarnos la p&eacute;rdida de tiempo de hacer la verificaci&oacute;n
          manual, as&iacute; como darnos de forma automatizada una lista de
          variantes y nombres similares para poder escoger el que m&aacute;s nos
          guste.
        </Paragraph>
        <Paragraph>
          Generar nombres al azar para Instagram o cualquier otra red social
          resulta una tarea trivial para una aplicaci&oacute;n de software dado
          que es posible contar con una base de datos de nombres populares,
          palabras atractivas, t&eacute;rminos a evitar, y variaciones
          frecuentes. Luego, partiendo de esa base, es posible realizar
          combinaciones y modificaciones para dar con nombres en su forma final,
          entre los cuales es posible filtrar los que no est&eacute;n
          disponibles dejando as&iacute; una lista de nombres para Instagram (o
          una red social cualquiera) que son originales y atractivos,
          adem&aacute;s de ser v&aacute;lidos para su uso inmediato.
        </Paragraph>
        <Heading2>
          Tampoco es necesario perder demasiado tiempo escogiendo
        </Heading2>
        <Paragraph>
          M&aacute;s all&aacute; de que pueda resultar atractivo el encontrar el
          nombre perfecto para nuestra nueva cuenta de Instagram reci&eacute;n
          creada, es importante recordar que no tenemos por qu&eacute; dar con
          una opci&oacute;n imbatible, simplemente hallar un buen nombre es
          m&aacute;s que suficiente, dado que la imagen de marca (ya sea
          personal, de empresa o de ficci&oacute;n) que vayamos a conseguir
          depender&aacute; de muchas otras cosas, destacando entre esos
          componentes el contenido que creemos y la difusi&oacute;n que
          consigamos dentro de la plataforma.
        </Paragraph>
        <Paragraph>
          Un buen nombre es un excelente punto de partida, pero es eso, un mero
          comienzo, no es la meta de llegada. Con esto en mente, es f&aacute;cil
          entender por qu&eacute; a veces no resulta sensato invertir demasiado
          tiempo y esfuerzo en el nombre cuando a&uacute;n nos falta definir
          c&oacute;mo lograremos conseguir que la gente llegue a nuestro
          contenido o, incluso, cu&aacute;l ser&aacute; este &uacute;ltimo.
        </Paragraph>
        <Paragraph>
          A pesar de que existan cuentas con nombres similares, es nuestro
          contenido y presencia lo que nos permitir&aacute; sentarnos en el
          trono de la popularidad en nuestro respectivo sector, y no el nombre
          en s&iacute; mismo, aunque siempre ayuda que sea uno atractivo y
          original.
        </Paragraph>
        <Heading3>
          Un nombre simple puede ser la elecci&oacute;n correcta
        </Heading3>
        <Paragraph>
          Otro punto a destacar es el de que la simpleza es muchas veces un
          punto fuerte al momento de escoger un nombre para Instagram, porque
          ser&aacute; m&aacute;s f&aacute;cil para nuestros seguidores el
          identificar nuestra marca y compartirla, adem&aacute;s de que les
          ayudar&aacute; a centrarse m&aacute;s en nuestro contenido que en
          nuestro nombre.
        </Paragraph>
        <Paragraph>
          Los nombres complejos suelen resultar &uacute;nicos y originales a
          costa de ser m&aacute;s dif&iacute;ciles de utilizar y recordar, por
          lo que no siempre es una buena idea el recurrir a un nombre de usuario
          que sea extenso o complicado para conseguir diferenciarnos del resto.
        </Paragraph>
        <Paragraph>
          Un aspecto atractivo de contar con un generador de nombres para
          Instagram es que podemos dar con muchas ideas que, si bien son
          atractivas y buenas candidatas para nuestra cuenta, no tienen por
          qu&eacute; ser complejas. Muchas veces un buen nombre consiste
          simplemente en alguna combinaci&oacute;n ingeniosa, juego de palabras
          o mutaci&oacute;n de una moda.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
